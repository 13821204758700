<template>
    <div class="color-txt">
        <s-form @submit="addItem">
        <h2 class="head font-poppins-semibold fs-lg-4 mt-3 text-primary">Artificial Insemination</h2>
        <div class="row">
            <div class="col-lg-8">
                <div class="row mt-4">
                    <div class="col-lg-3">
                        <validated-vue-select class="c-input-select input-border-primary field-required" label="Cow Type" placeholder="Cow Type"
                                               v-model="model.cow_type" @input="loadCowOptions"
                                               :options="cowTypeOptions" :rules="{required:true}"></validated-vue-select>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-lg-3">
                    <validated-vue-select :options="cowOptions"  @input="loadDetails" class="c-input-select field-required"
                                          label="Reg No" v-model="model.cow" :rules="{required:true}"></validated-vue-select>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 mb-3">
                        <h3 class="text-primary fs-lg-2 font-poppins-medium">Details of Heat</h3>
                    </div>
                    <div class="col-lg-3">
                        <validated-date-picker format="DD-MM-YYYY" label="Heat Date" v-model="model.heat_date" class="c-input-datepicker text-black field-required" :rules="rules.checkDate"></validated-date-picker>
                    </div>
                    <div class="col-lg-3">
                        <validated-input label="Time" v-model="model.time" class="text-black" placeholder="HH:MM" :rules="rules.Time"></validated-input>
                    </div>
                    <div class="col-lg-3">
                        <validated-select label="Induced / Natural" :options="inducedOptions" v-model="model.induced_or_natural" class="c-input-select text-black field-required" :rules="{required:true}"></validated-select>
                    </div>
                    <div class="col-lg-3">
                        <validated-input label="Drug Used" v-model="model.drug_used" class="text-black"></validated-input>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-3">
                        <validated-date-picker format="DD-MM-YYYY" label="AI Date" v-model="model.ai_date" @input="loadDetails" class="c-input-datepicker text-black field-required" :rules="rules.checkDate"></validated-date-picker>
                    </div>
                    <div class="col-lg-4">
                        <validated-checkbox label="Double Insemination" v-model="model.double_insemination" class="mt-lg-4"></validated-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-3">
                        <validated-input label="Remarks" v-model="model.remarks" class="text-black"></validated-input>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <custom-single-column-table class="ml-lg-6">
                    <template #default>
                        <ul class="list-unstyled list-items ml-2" v-if="!$slots.default">
                            <li><span>Shed No.</span><span class="ml-1">:</span>{{shed_number}}</li>
                            <li><span>Breed </span><span class="ml-1">:</span>{{breed}}</li>
                            <li><span>Date of Birth</span> <span class="ml-1">:</span>{{dob}}</li>
                            <li><span>Bull Allotted</span><span class="ml-1">:</span>{{bull_allotted}}</li>
                            <li><span>Bull Breed</span><span class="ml-1">:</span>{{bull_breed}}</li>
                            <li><span>Attempt No</span> <span class="ml-1">:</span>{{attempt_no}}</li>
                        </ul>
                    </template>
                </custom-single-column-table>
            </div>
        </div>
        <div class="row mt-lg-5">
            <div class="col-12">
                <div class="fl-x fl-j-e">
                    <div class="btn-group">
                        <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                        <btn text="Cancel" type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
                   </div>
                </div>
            </div>

        </div>
        </s-form>
    </div>
</template>
<script>
// import CustomTwoColumnTable from '../../../components/ui/CustomTwoColumnTable';
// import masterURLs from '../../../data/masterURLs';
import urls from '../../../data/urls';
import axios from 'secure-axios';
import CustomSingleColumnTable from '../../../components/ui/CustomSingleColumTable';

export default {
    name: 'ArtificialInsemination',
    components: { CustomSingleColumnTable },
    data () {
        return {
            cow_type: '',
            loading: false,
            URL: urls.artificial_insemination.addEdit,
            model: {
                cow_type: '',
                cow: '',
                heat_date: '',
                time: '',
                induced_or_natural: '',
                drug_used: '',
                ai_date: '',
                double_insemination: '',
                remarks: ''
            },
            rules: {
                checkDate: {
                    required: true,
                    customValidator: (value) => {
                        return this.DateValidation(value);
                    }
                },
                checkAIDate: {
                    required: true,
                    customValidator: (value) => {
                        return this.AIDateValidation(value);
                    }
                },
                Time: {
                    required: false,
                    customValidator: (value) => {
                        return this.TimeValidation(value);
                    }
                }
            },
            cowOptions: [],
            breed: '',
            shed_number: '',
            dob: '',
            bull_allotted: '',
            bull_breed: '',
            attempt_no: '',
            cowTypeOptions: [

                { label: 'Heifer', value: 'Heifer' },
                { label: 'Cow', value: 'Cow' }
            ],
            inducedOptions: [
                { label: 'Induced', value: 'Induced' },
                { label: 'Natural', value: 'Natural' }
            ]
        };
    },
    methods: {
        TimeValidation (value) {
            const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
            if (!timeRegex.test(value)) {
                return 'Invalid time format. Please use HH:MM format.';
            }
            return true;
        },
        BackToHome () {
            this.$router.push({ path: '/ah-section/' });
        },
        async loadCowOptions () {
            this.model.cow = '';
            const response = await axios.get(urls.artificial_insemination.cowAIVueSelect + '?cow_type=' + this.model.cow_type);
            this.cowOptions = response.data.data;
        },
        DateValidation (checkDate) {
            const dob = this.dob;
            const checkDateParts = checkDate.split('-');
            if (checkDateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const checkDay = parseInt(checkDateParts[0], 10);
            const checkMonth = parseInt(checkDateParts[1], 10);
            const checkYear = parseInt(checkDateParts[2], 10);

            const dobParts = dob.split('-');
            if (dobParts.length !== 3) {
                return ' ';
            }
            const dobDay = parseInt(dobParts[0], 10);
            const dobMonth = parseInt(dobParts[1], 10);
            const dobYear = parseInt(dobParts[2], 10);

            if (isNaN(checkDay) || isNaN(checkMonth) || isNaN(checkYear) || isNaN(dobDay) || isNaN(dobMonth) || isNaN(dobYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const checkDateObj = new Date(checkYear, checkMonth - 1, checkDay);
            const dobDateObj = new Date(dobYear, dobMonth - 1, dobDay);

            if (checkDateObj <= dobDateObj) {
                return 'Date should be greater than the date of birth (DOB).';
            }
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (checkDateObj > currentDate) {
                return 'Future Date not allowed.';
            }

            return true;
        },
        AIDateValidation (checkDate) {
            const dob = this.dob;
            const checkDateParts = checkDate.split('-');

            if (checkDateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }

            const checkDay = parseInt(checkDateParts[0], 10);
            const checkMonth = parseInt(checkDateParts[1], 10);
            const checkYear = parseInt(checkDateParts[2], 10);

            const dobParts = dob.split('-');
            if (dobParts.length !== 3) {
                return ' ';
            }
            const dobDay = parseInt(dobParts[0], 10);
            const dobMonth = parseInt(dobParts[1], 10);
            const dobYear = parseInt(dobParts[2], 10);

            if (isNaN(checkDay) || isNaN(checkMonth) || isNaN(checkYear) || isNaN(dobDay) || isNaN(dobMonth) || isNaN(dobYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const checkDateObj = new Date(checkYear, checkMonth - 1, checkDay);

            const dobDateObj = new Date(dobYear, dobMonth - 1, dobDay);

            if (checkDateObj <= dobDateObj) {
                return 'Date should be greater than the date of birth (DOB).';
            }

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (checkDateObj > currentDate) {
                return 'Future Date not allowed.';
            }

            return true;
        },
        async loadDetails () {
            this.breed = '';
            this.shed_number = '';
            // this.dob = '';
            this.bull_allotted = '';
            this.bull_breed = '';
            this.attempt_no = '';
            const response = await axios.get(urls.artificial_insemination.cowDetails + '?id=' + this.model.cow + '&ai_date=' + this.model.ai_date);
            const data = response.data.data;
            if (response.data.error) {
                this.$notify(response.data.message, 'Warning', { type: 'warning' });
            } else {
                this.breed = data.breed;
                this.shed_number = data.shed_no;
                this.dob = data.dob;
                this.bull_allotted = data.bull_allotted;
                this.bull_breed = data.bull_breed;
                this.attempt_no = data.attempt_no;
            }
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/ah-section/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>;
